import axios from 'axios'
import { NEW_RELIC_APP_NAME, NEW_RELIC_GUID_ID, NEW_RELIC_LOG_API, NEW_RELIC_LOG_API_KEY } from './constants'
import { BASE_URL } from '@framework/utils/constants'

export enum LOGGER_TYPE {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export enum ENV_FROM_BASE_URL {
  DEV = 'DEV',
  BETA = 'BETA_NEW',
  PROD = 'PROD_NEW',
}
export interface ILogger {
  apiPath: string
  message: string
  error?: string
  type: LOGGER_TYPE
  pageType: string
  fetcherStartTime?: number
  fetcherEndTime?: number
  fetcherResponseTime?: number
  operationsEndTime?: number
  operationsStartTime?: number
  operationsResponseTime?: number
  apiCatalogStartTime?: number
  apiCatalogEndTime?: number
  apiCatalogResponseTime?: number
  failedOn?: string
  machineId: string
  functionType?: string
  level?: string
  loginStatus?: string
  addressScreen?: string
  payMode?: string
}

export const storeLogs = async (log: ILogger) => {
  const isLocal = process?.env?.IS_LOCAL && process.env.IS_LOCAL === 'true'
  const getEnv = (baseUrl: string) => {
    if (baseUrl?.includes('dev-ocxapi-20.azurewebsites.net')) {
      return ENV_FROM_BASE_URL.DEV
    } else if (baseUrl?.includes('api20.betabettercommerce.com')) {
      return ENV_FROM_BASE_URL.BETA
    } else if (baseUrl?.includes('api20.bettercommerce.io')) {
      return ENV_FROM_BASE_URL.PROD
    } else {
      return undefined
    }
  }

  if (!!NEW_RELIC_LOG_API) {
    await axios.post(`${NEW_RELIC_LOG_API}`,
      {
        ...log,
        logtype: log.type,
        env: getEnv(BASE_URL || ''),
        attributes: {
          'entity.guid': `${NEW_RELIC_GUID_ID}`,
          'entity.name': `${NEW_RELIC_APP_NAME}`,
        },
      },
      {
        headers: {
          'Api-Key': `${NEW_RELIC_LOG_API_KEY}`,
        },
      }
    )
  }
}

export const generateSuccessStoreLog = ({ apiPath, message, startTime, endTime, level, pageType = '', machineId = '' }: any) => ({
  machineId,
  fetcherResponseTime: endTime - startTime,
  fetcherStartTime: startTime,
  fetcherEndTime: endTime ,
  message,
  apiPath,
  type: LOGGER_TYPE.SUCCESS,
  pageType,
  level,
})

export const generateErrorStoreLog = ({ apiPath, message, startTime, endTime, pageType = '', machineId = '' }: any) => ({
  machineId,
  fetcherResponseTime: endTime - startTime,
  fetcherStartTime: startTime,
  fetcherEndTime: endTime ,
  message,
  apiPath,
  type: LOGGER_TYPE.ERROR,
  pageType,
  level: LOGGER_TYPE.ERROR,
})