import { matchStrings } from "@framework/utils/parse-util"
import { LocalStorage } from "./payment-constants"

export const setItem = (key: string, data: any) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(data))
  }
}

export const getItem = (key: string) => {
  let item: string | null = null
  if (typeof window !== 'undefined') {
    item = localStorage?.getItem(key)
  }
  return item
    ? item.toString() != 'undefined'
      ? JSON.parse(item)
      : null
    : null
}

export const removeItem = (key: string) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key)
  }
}

/**
 * Retrieves a specific value from the Firebase remote configuration stored in localStorage.
 * 
 * @param {string} key - The key whose value needs to be fetched from the remote configuration.
 * @param {boolean} [ignoreCase=false] - A flag to control whether the key comparison is case-sensitive (default is `false` for case-insensitive).
 * 
 * @returns {boolean | null} - Returns:
 *   - The boolean value of the key if it exists.
 *   - `false` if the key does not exist in the configuration or its value is falsy.
 *   - `null` if the function is called in a server environment or the key is invalid.
 */
export const getRemoteConfigValue = (key: string, ignoreCase: boolean = false): boolean | null => {
  if (typeof window === 'undefined' || !key) return null;

  const item = getItem(LocalStorage.Key.FIREBASE_REMOTE_CONFIG);
  if (ignoreCase && item) {
    const matchedKey = Object?.keys(item)?.find((k) => matchStrings(k, key, true));
    return matchedKey ? item[matchedKey] : false;
  }
  return item?.[key] || false;
};
