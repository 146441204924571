import { asyncHandler } from '@components/account/Address/helper'
import cartHandler from '@components/services/cart'
import { useUI, basketId as generateBasketId } from '@components/ui/context'
import {
  BETTERCOMMERCE_DEFAULT_COUNTRY,
  BETTERCOMMERCE_DEFAULT_COUNTRY_CODE,
  CONVERT_ORDER_VALIDATION_MESSAGES,
  EmptyGuid,
  Messages,
  NEXT_SHIPPING_ENDPOINT,
} from '@components/utils/constants'
import { JusPay, LocalStorage } from '@components/utils/payment-constants'
import {
  extractStoreIdFromString,
  getDeliveryAddress,
  getOrderId,
  getOrderInfo,
  setDeliveryAddress,
} from '@framework/utils/app-util'
import {
  APPLY_JUSPAY_OFFERS_DISABLED,
  GOKWIK_COD_METHOD_SYSTEM_NAME,
  IS_TEST_PAYMENT_ENABLED_ON_LIVE,
  JUSPAY_OFFERS_PROMO_CODE,
  JUSPAY_PAY_METHOD_SYSTEM_NAME,
} from '@framework/utils/constants'
import {
  createJusPayOrder,
  getJusPayOffers,
} from '@framework/utils/juspay-util'
import {
  matchStrings,
  stringToBoolean,
  stringToNumber,
} from '@framework/utils/parse-util'
import Cookies from 'js-cookie'
import Router, { useRouter } from 'next/router'
import { useState } from 'react'
import {
  fetchPaymentMethod,
  handleAsync
} from '../helper'
import axios from '@services/axiosInterceptorInstance'
import { decrypt, encrypt } from '@framework/utils/cipher'
import setSessionIdCookie from '@components/utils/setSessionId'
import { fetchJusPayPaymentMethods, IOffersInput } from '@components/checkout/helper'

interface IProps {
  config: any
  isChannelStore: boolean
}

const useBasketExpressCheckout = ({ config, isChannelStore }: IProps) => {
  const router = useRouter()
  const storeId =
    router?.query?.storeId || extractStoreIdFromString(router?.asPath)
  const { associateCart } = cartHandler()
  const {
    user,
    cartItems,
    setCartItems,
    basketId,
    setBasketId,
    storeBasketId,
    paymentMethods,
    setPaymentMethods,
    resetCartStorage,
    setOverlayLoaderState,
    hideOverlayLoaderState,
    setAlert,
  } = useUI()
  const { getAddress } = asyncHandler()
  const [defaultShippingAddress, setDefaultShippingAddress] =
    useState<any>(undefined)
  const [applicableCoupon, setApplicableCoupon] = useState<any | undefined>(
    undefined
  )
  const [applicableOffers, setApplicableOffers] = useState<
    Array<any> | undefined
  >(undefined)
  const shippingCountry = config?.shippingCountries?.length
    ? config?.shippingCountries[0]
    : null
  const isTestPayment = stringToBoolean(IS_TEST_PAYMENT_ENABLED_ON_LIVE)

  const getUserId = () => {
    return user?.userId && user?.userId != EmptyGuid
      ? user?.userId
      : cartItems?.userId
  }

  const fetchAddress = async (userId?: string) => {
    try {
      const response: any = await getAddress(userId ?? getUserId())
      const shippingAddress = response.find(
        (item: any) => item.isDefault || item.isDefaultDelivery
      )
      if (isChannelStore && !shippingAddress) {
        const addressInfo: any = Cookies?.get('storeDetails')
        const addressJson = addressInfo && JSON.parse(addressInfo)
        setDeliveryAddress(addressJson)
        setDefaultShippingAddress(addressJson)
      } else if (shippingAddress) {
        const deliveryAddress = getDeliveryAddress()
        if (!deliveryAddress) {
          setDeliveryAddress(shippingAddress)
        }
        setDefaultShippingAddress(shippingAddress)
      }
    } catch (error) {
      console.error(':: error ', error)
    }
  }

  const fetchJusPayPromos = async (
    cartItems: any,
    paymentMethodInfo?: IOffersInput
  ) => {
    const isCouponAutoApplyApplicable =
      !APPLY_JUSPAY_OFFERS_DISABLED && paymentMethodInfo
    if (isCouponAutoApplyApplicable) {
      setOverlayLoaderState({ visible: true, message: 'Applying offer...' })
    }
    let data = {
      order: {
        amount: cartItems?.grandTotal?.raw?.withTax?.toString(),
        currency: cartItems?.baseCurrency,
      },
      customer: {
        id: getUserId(),
      },
    }

    if (isCouponAutoApplyApplicable) {
      let additionalData = {
        payment_method_type: paymentMethodInfo?.methodType,
        payment_method_reference: paymentMethodInfo?.methodRef,
      }
      if (paymentMethodInfo?.extras) {
        for (let [key, value] of Object.entries(paymentMethodInfo?.extras)) {
          additionalData = { ...additionalData, ...{ [key]: value } }
        }
      }

      data = {
        ...data,
        ...{
          payment_method_info: [additionalData || {}],
        },
      }
    }

    const authToken =
      localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''

    const { data: offersResult } = await getJusPayOffers(
      data,
      authToken,
      user?.userId
    )

    if (isCouponAutoApplyApplicable) {
      if (offersResult?.best_offer_combinations?.length) {
        setApplicableCoupon(offersResult?.best_offer_combinations[0])
        setApplicableOffers(
          offersResult?.offers?.length > 0
            ? offersResult?.offers?.filter((x: any) =>
                matchStrings(
                  x?.status,
                  JusPay.Offers.ELIGIBLE_OFFER_STATUS,
                  true
                )
              )
            : []
        )
      } else {
        setApplicableCoupon(undefined)
        setApplicableOffers(undefined)
      }
      hideOverlayLoaderState()
    }
    return offersResult
  }

  const handleFetchOffers = async (data?: IOffersInput) => {
    await fetchJusPayPromos(cartItems, data)
  }

  const handleConvertOrder = async (etaDays: any, paymentMethodName?: string) => {
    if (defaultShippingAddress?.id) {
      const msgCode = await initiateOrder(etaDays, paymentMethodName)
      if (msgCode) {
        if (Messages.Errors[msgCode]) {
          setAlert({ type: 'error', msg: Messages.Errors[msgCode] })
        } else {
          setAlert({ type: 'error', msg: msgCode })
        }
      }

      if (CONVERT_ORDER_VALIDATION_MESSAGES.includes(msgCode)) {
        setTimeout(async () => {
          await generateNewBasketAndRedirect()
        }, 500)
      }
      return !msgCode
    }
    return false
  }

  const handlePaymentMethods = async () => {
    const { data: paymentMethodsResult }: any = await fetchJusPayPaymentMethods()
    setPaymentMethods(paymentMethodsResult?.payment_methods)
  }

  const initiateOrder = async (etaDays: any, paymentMethodName?: string) => {
    let msgCode = ''
    resetCartStorage()

    // Fetch payment methods
    const { data: paymentMethods } = await fetchPaymentMethod(
      cartItems,
      shippingCountry
    )

    const jusPayPaymentMethod = paymentMethods?.length
      ? paymentMethods?.find((x: any) =>
          matchStrings(x?.systemName, JUSPAY_PAY_METHOD_SYSTEM_NAME || '', true)
        )
      : undefined
    let shippingMethod = cartItems?.shippingMethods?.length
      ? cartItems?.shippingMethods[0]
      : null

    if (!shippingMethod?.id) {
      const { data: shippingMethodsResult }: any = await axios.post(
        NEXT_SHIPPING_ENDPOINT,
        {
          basketId: isChannelStore ? storeBasketId : basketId,
          countryCode:
            shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
        }
      )

      if (shippingMethodsResult?.length) {
        shippingMethod = shippingMethodsResult[0]
      }
    }

    if (jusPayPaymentMethod?.id && shippingMethod?.id) {
      if (!localStorage?.getItem(LocalStorage.Key.CONVERTED_ORDER)) {
        msgCode = await confirmOrder(etaDays, jusPayPaymentMethod, defaultShippingAddress, shippingMethod, paymentMethodName)
      }
    }
    return msgCode
  }

  const confirmOrder = async (
    etaDays: any,
    method: any,
    shippingAddress: any,
    shippingMethod: any,
    paymentMethodName?: string,
    additionalServiceCharge?: any,
    isCOD?: boolean
  ) => {
    const billingInfoClone = { ...shippingAddress }
    //delete billingInfoClone.id;
    const shippingClone = { ...shippingAddress }
    //delete shippingClone.id;
    return await handleAsync(
      isChannelStore,
      storeBasketId,
      basketId,
      user,
      billingInfoClone,
      shippingCountry,
      shippingClone,
      shippingMethod,
      method,
      cartItems,
      paymentMethodName,
      additionalServiceCharge,
      isTestPayment,
      isCOD,
      etaDays
    )
  }


  const confirmJusPayOrder = async (
    method: any,
    paymentMethodName?: string,
    paymentMethodInfo?: string,
    savePspInfo: boolean = true
  ) => {
    savePspInfo = matchStrings(
      paymentMethodName ?? '',
      GOKWIK_COD_METHOD_SYSTEM_NAME ?? '',
      true
    )
      ? false
      : true

    const getJusPayOfferDetails = () => {
      if (applicableCoupon?.offers?.length) {
        const offerId = applicableCoupon?.offers[0]?.offer_id
        if (offerId) {
          const offer = applicableOffers?.find((x: any) =>
            matchStrings(x?.offer_id, offerId, true)
          )
          const offerCode = offer?.offer_code ?? ''
          const value =
            offer?.order_breakup?.benefits?.length > 0
              ? offer?.order_breakup?.benefits[0]?.value?.toString()
              : ''
          const calcRule =
            offer?.order_breakup?.benefits?.length > 0
              ? offer?.order_breakup?.benefits[0]?.calculation_rule
              : ''
          const discountedTotal = offer?.order_breakup?.final_order_amount ?? ''

          return {
            voucherCode: JUSPAY_OFFERS_PROMO_CODE,
            offerCode: offerCode,
            value: value,
            status:
              calcRule === JusPay.Offers.CalculationRuleType.ABSOLUTE
                ? 'false'
                : 'true',
            discountedTotal: stringToNumber(discountedTotal.toString()),
          }
        }
      }
      return ''
    }

    const decryptedOrderModal = decrypt(
      localStorage?.getItem(LocalStorage.Key.ORDER_PAYMENT) as string
    )
    const orderModel = JSON.parse(decryptedOrderModal)
    const decryptedOrderResponse = decrypt(
      localStorage?.getItem(LocalStorage.Key.ORDER_RESPONSE) as string
    )
    const orderResponse = {
      result: JSON.parse(decryptedOrderResponse),
    }

    const billingInfoClone = { ...defaultShippingAddress }
    //delete billingInfoClone.id;
    const shippingClone = { ...defaultShippingAddress }
    //delete shippingClone.id;

    const confirmOrderInput = {
      billingAddress: {
        ...billingInfoClone,
        country:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
        countryCode:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
      },
      shippingAddress: {
        ...shippingClone,
        country:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
        countryCode:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
      },
    }

    let jusPayDetails: any = getJusPayOfferDetails()
    const authToken =
      localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
    const juspayOrderData = {
      txnOrderId: orderResponse?.result?.id,
      orderId: getOrderId(orderModel), //orderResponse?.result?.id,
      productId: cartItems?.id,
      customerId: getUserId(),
      currencyCode: cartItems?.baseCurrency,
      customerEmail: cartItems?.userEmail,
      customerPhone: defaultShippingAddress?.phoneNo,
      billingAddress: confirmOrderInput?.billingAddress,
      shippingAddress: confirmOrderInput?.shippingAddress,
      twoLetterIsoCode:
        shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
      mobileCountryCode: BETTERCOMMERCE_DEFAULT_COUNTRY_CODE,
      applyOffersDisabled: APPLY_JUSPAY_OFFERS_DISABLED,
      jusPayDetails,
      methodId: method?.id,
      methodName: paymentMethodName ?? method?.systemName,
      paymentMethodInfo,
      savePspInfo,
      authToken,
    }

    const { data: jusPayOrderResult } = await createJusPayOrder(juspayOrderData)

    const orderInfoUpdated = await getOrderInfo()
    if (orderInfoUpdated) {
      orderInfoUpdated.jusPayOrder = jusPayOrderResult
      const encryptedOrderInfoUpdated = encrypt(
        JSON.stringify(orderInfoUpdated)
      )
      localStorage.setItem(
        LocalStorage.Key.CONVERTED_ORDER,
        encryptedOrderInfoUpdated
      )
    }
    return jusPayOrderResult
  }

  const initiateJusPayOrder = async (
    paymentMethodName: string,
    isCOD: boolean = false,
    paymentMethodInfo: string = ''
  ) => {
    const orderInfo = await getOrderInfo()

    if (!orderInfo?.jusPayOrder?.orderId) {
      const jusPayPaymentMethod = !isCOD
        ? paymentMethods?.length
          ? paymentMethods?.find((x: any) =>
              matchStrings(
                x?.systemName,
                JUSPAY_PAY_METHOD_SYSTEM_NAME || '',
                true
              )
            )
          : undefined
        : paymentMethods?.length
        ? paymentMethods?.find((x: any) =>
            matchStrings(x?.systemName, paymentMethodName, true)
          )
        : undefined
      const justPayOrderResult = await confirmJusPayOrder(
        jusPayPaymentMethod,
        paymentMethodName,
        paymentMethodInfo
      )
      return justPayOrderResult
    }
    return orderInfo?.jusPayOrder
  }

  const showLoader = () => {
    setOverlayLoaderState({ visible: true, message: 'Please wait...' })
  }

  const hideLoader = () => {
    setTimeout(() => {
      hideOverlayLoaderState()
    }, 400)
  }

  const generateNewBasketAndRedirect = async () => {
    Cookies.remove('sessionId')
    setSessionIdCookie()
    if (isChannelStore) {
      Cookies.remove('storeBasketId')
    } else {
      Cookies.remove('basketId')
    }
    const generatedBasketId = generateBasketId()
    setBasketId(generatedBasketId)
    const userId = cartItems.userId
    const newCart = await associateCart(userId, generatedBasketId)
    setCartItems(newCart?.data)
    Router.replace('/')
  }

  return {
    fetchAddress,
    handleFetchOffers,
    handleConvertOrder,
    handlePaymentMethods,
    initiateJusPayOrder,
    showLoader,
    hideLoader,
    setDefaultShippingAddress,
    defaultShippingAddress,
    storeId,
    applicableCoupon,
    user,
    cartItems,
    setAlert,
    getUserId,
    shippingCountry,
    confirmOrder
  }
}

export { useBasketExpressCheckout }
